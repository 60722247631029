import React from 'react'

const AddNetworkButton = () => {
  // Функция для добавления кастомной сети
  const addNetwork = async () => {
    if (window.ethereum && window.ethereum.request) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x15AEB', // Идентификатор сети в формате hex (например, для Polygon это '0x89')
              chainName: 'Unit Zero Mainnet', // Имя сети
              nativeCurrency: {
                name: 'UNIT0', // Название нативной валюты сети
                symbol: 'UNIT0', // Символ валюты
                decimals: 18 // Десятичные знаки
              },
              rpcUrls: ['https://rpc.unit0.dev/'], // URL для RPC-сервера сети
              blockExplorerUrls: ['https://explorer.unit0.dev/'] // URL для обозревателя блоков
            }
          ]
        })
      } catch (error) {
        console.error('Error on network adding:', error)
      }
    } else {
      alert('MetaMask is not installed. Please install MetaMask and try again.')
    }
  }

  return (
    <button onClick={addNetwork} style={{ padding: '10px 20px', fontSize: '16px' }}>
      Add UNIT0 to MetaMask
    </button>
  )
}

export default AddNetworkButton
